import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/', 
      name: 'Home',
      component: () => import("@/components/BackgroundVideo.vue"),
    },
    {
      path: '/first-users',
      name: 'FirstUsers',
      component: () => import("@/views/FirstUsers")
    },
    {
      path: '/first-customers',
      name: 'FirstCustomers',
      component: () => import("@/views/FirstCustomers")
    },
    {
      path: '/invest-with-us',
      name: 'InvestWithUs',
      component: () => import("@/views/InvestWithUs")
    }
  ]
})
