<template>
  <div id="app">
    <NavBar class="navegador" @ChangeLanguage="ChangeLanguage"/>
    <div class="espacio"></div>
    <router-view :language="language"/>
    <FooterCustom/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import FooterCustom from './components/FooterCustom.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    FooterCustom,
  },
  data() {
    return {
      language: 'ENGLISH',
    }
  },
  methods:{
    ChangeLanguage(language) {
      console.log("*");
      console.log(language);
      this.language = language;
    },
  }
}
</script>

<style>
  .navegador{
    position: fixed;
    width: 100%;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.espacio{
  height: 65px;
}
</style>
