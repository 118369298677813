<template>
  <div class="container">
      <div class="col-md-6 offset-md-3">
        <hr style="height:1px;background:#000">
        <div class="row container-footer">
            <div class="col-md-6 text-left">
                Copyright 2022 LetZTrav. Todos los derechos reservados
            </div>
            <div class="col-md-6 text-right">
                Política de privacidad
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container-footer{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 10px;
    color: #cacdcf;
}
</style>