import '@/assets/css/main.css'
import Vue from 'vue'
import App from './App.vue'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import router from './router'
import VueYouTubeEmbed from "vue-youtube-embed";
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

Vue.use(VueYouTubeEmbed);
Vue.config.productionTip = false

Vue.prototype.$language = 'ENGLISH'

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
