<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark bg-custom-ztravel">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 col-md-5 offset-md-5">
                        <li class="nav-item">
                            <router-link class="decoration-none" to="/">
                                <img class="img-fluid menu-img" alt="logo" src="https://public-resource-letztrav.s3.amazonaws.com/img-test.png">
                            </router-link>
                        </li>
                        <li class="nav-item itemmenu">
                            <router-link class="decoration-none" to="/">
                                <a class="nav-link" aria-current="page" href="#"><h5 class="menu-link">DEMO</h5></a>
                            </router-link>
                        </li>
                        <li class="nav-item itemmenu">
                            <router-link class="decoration-none" to="/first-users">
                                <a class="nav-link" href="#"><h5 class="menu-link">FIRST USERS</h5></a>
                            </router-link>
                        </li>
                        <li class="nav-item itemmenu">
                            <router-link class="decoration-none" to="/first-customers">
                                <a class="nav-link" href="#"><h5 class="menu-link">FIRST CUSTOMER</h5></a>
                            </router-link>
                        </li>
                        <li class="nav-item itemmenu">
                            <router-link class="decoration-none" to="/invest-with-us">
                                <a class="nav-link" href="#"><h5 class="menu-link">INVEST WITH US</h5></a>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="language">
                    <select name="language" v-model="language">
                        <option value="ENGLISH">🇺🇸&emsp;English</option>
                        <option value="SPANISH">🇪🇸&emsp;Spanish</option>
                    </select>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'NavBar',
    data (){
      return {
        language: 'ENGLISH'
      }
    },
    watch:{
        language: function(){
            console.log(this.language);
            this.$emit('ChangeLanguage', this.language);
        }
    }
}
</script>

<style>
    .language {float:right;}
.bg-custom-ztravel{
    background-color: #002fa5; 
}

.menu-img{
    margin-top: 3px;
    width: 40px;
}

.decoration-none .router-link-exact-active .router-link-active {
  color: #f19dc0;
}

.menu-link {
    font-size: 10px;
    /*color: white;*/
    
    letter-spacing: 1px;
    padding-top: 10px;
    /*text-decoration: none;*/
}
.decoration-none{
    text-decoration: none;
}

.navbar .router-link-exact-active  a{
  color: #f19dc0;
  font-size: 15px;
}

.itemmenu a:hover{
    color: #003686;
    background-color: #f19dc0;
}

</style>